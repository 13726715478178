button {
    font-family: "Lato", sans-serif;
    font-size: 2vh;
    font-weight: bold;
    border-radius: 5vh;
    height: 7vh;
    width: 10vh;
    background-color: gray;
    border-style: none;

    &:hover {
        cursor: pointer;
        background-color: lightgray;
    }
}

h2 {
    font-size: 7vh;
    text-align: center;
    font-weight: bold;
    padding-bottom: 2.5vh;
    margin: 0 !important;
    font-family: "Lato", sans-serif;
}

.maze {
    background-color: white;
    width: 30vw;
    height: 30vw;
    max-width: 450px;
    max-height: 450px;
}

.mazeHolder {
    padding-bottom: 2.5vh;
}

.pathfinder {
    background-color: rgba(155, 143, 150, 1);
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.projectButton > * {
    margin-right: 1vh;
    margin-left: 1vh;
    margin-bottom: 2vh;
}

@media only screen and (max-width: 500px) {
    .maze {
        width: 80vw;
        height: 80vw;
    }
}
