.experience {
    box-sizing: border-box;
    width: 100%;
    min-height: 100vh;
    background-color: white;
    font-family: "Lato", sans-serif;
    padding-bottom: 5vh;
}

.experience-jobs {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.experience-label {
    font-weight: bold;
    font-size: 4vh;
    box-sizing: border-box;
    padding-left: 20vh;
    padding-top: 6.5vh;
    padding-bottom: 7.5vh;
}

.job {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    width: 60vw;
    height: 40vh;
    box-shadow: 0 0 3vh 3vh rgba(0, 0, 0, 0.075);
    overflow: hidden;
    border-radius: 5vh;
    transition: 0.25s;

    &:hover {
        box-shadow: 0 0 2vh 2vh rgba(0, 0, 0, 0.15);
    }
}

.job-company {
    font-weight: bold;
}

.job-container {
    padding-bottom: 5vh;
    text-decoration: none;
    color: black;
}

.job-details {
    display: flex;
    flex-direction: row;
    font-size: 1.75vh;
}

.job-image {
    height: 40vh;
}

.job-image-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.job-link {
    &:hover {
        cursor: pointer;
    }
}

.job-skills {
    display: flex;
    flex-direction: row;
    padding-top: 2vh !important;
    & > * {
        width: 4vh;
        padding-right: 1vw;
    }
}

.job-title {
    font-size: 3vh;
}

.job-text {
    position: relative;
    display: flex;
    flex-direction: column;
    margin: 5vh;

    & > * {
        padding: 1vh;
    }
}

@media only screen and (max-width: 500px) {
    .experience {
        padding-bottom: 0;
    }

    .experience-label {
        padding-left: 0;
        padding-right: 0;
        text-align: center;
    }

    .job {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr;
        width: 100%;
        height: 70vh;
        box-sizing: border-box;
        box-shadow: 0 0 1vh 1vh rgba(0, 0, 0, 0.075);
        border-radius: 5vh;
    }

    .job-container {
        padding-bottom: 1vh;
    }

    .job-skills {
        & > * {
            padding-right: 2vw;
        }
    }
}
