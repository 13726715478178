.icon {
    color: black !important;
    font-size: 2vh;
    padding: 1vh;
    &:hover {
        cursor: pointer;
    }
}

.icon-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    overflow: hidden;
    position: fixed;
    padding-top: 1vh;
    padding-left: 2vh;
    top: 0;
    left: 0;
    width: 2vh;
    height: auto;
}
