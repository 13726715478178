.projects {
    box-sizing: border-box;
    width: 100%;
    background-color: white;
    font-family: "Lato", sans-serif;
    padding-bottom: 10vh;
}

.projects-label {
    font-weight: bold;
    font-size: 4vh;
    box-sizing: border-box;
    padding-left: 20vh;
    padding-bottom: 7.5vh;
}
