@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@700&family=Righteous&display=swap");

canvas {
    width: 100%;
    height: 100vh;
}

.chest {
    position: relative;
    height: 10vh;
    -webkit-transform: rotate(5deg);
    -moz-transform: rotate(5deg);
    -ms-transform: rotate(5deg);
    -o-transform: rotate(5deg);
    transform: rotate(5deg);
}

.gif-background {
    overflow: hidden;
    width: 100%;
    height: 100vh;
}

@keyframes jellyfish {
    from {
        top: 25vh;
    }
    to {
        top: 29vh;
    }
}

.jellyfish {
    position: relative;
    height: 10vh;
    animation-name: jellyfish;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    animation-duration: 1.5s;
    animation-timing-function: ease-in-out;
}

.ocean {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;

    position: relative;
    z-index: 0;

    & > * {
        position: absolute;
        top: 0;
        left: 0;
        height: 100vh;
        width: 100%;
    }

    $children: 12;
    @for $i from 0 to $children {
        & > *:nth-child(#{$i + 1}) {
            z-index: #{- (($children)- ($i))};
        }
    }
}

.ocean-name-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    z-index: -3;
}

.ocean-title {
    color: black;
    font-family: "Josefin Sans", sans-serif;
    font-weight: 900;
    font-size: 6rem;
    margin-top: 0.2em;
    font-weight: 700;
    line-height: 0.9em;
    white-space: nowrap;
}

.ocean-title2 {
    color: black;
    font-family: "Josefin Sans", sans-serif;
    font-weight: 900;
    font-size: 2.4rem;
    margin-top: 0.2em;
    font-weight: 700;
    line-height: 0.9em;
    white-space: nowrap;
    padding-bottom: 7.5vh;
}

.shipwreck {
    position: relative;
    height: 30vh;
}

@keyframes turtle {
    from {
        top: 15vh;
    }
    to {
        top: 12vh;
    }
}

.turtle {
    position: relative;
    height: 7.5vh;
    animation-name: turtle;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    animation-duration: 1.5s;
    animation-timing-function: ease-in-out;
}

.water {
    background: #63d1f2;
}

@media only screen and (max-width: 500px) {
    .ocean-title {
        font-size: 5rem;
    }

    .ocean-title2 {
        font-size: 2em;
    }

    .shipwreck {
        display: none;
    }

    .jellyfish {
        display: none;
    }

    .ocean > *:nth-child(5) {
        display: none;
    }
}
