.navbar {
    display: flex;
    flex-direction: row-reverse;
    justify-content: right;
    overflow: hidden;
    position: fixed;
    padding-top: 1vh;
    top: 0;
    width: 100%;
    height: auto;
}

.navbar-icon {
    font-size: 4vh;
}

.navbar-section {
    text-decoration: none;
    color: black !important;
    font-size: 2.5vh;
    font-weight: bold;
    padding-top: 1vh;
    padding-right: 1.5vw;
    padding-left: 1.5vw;
    font-family: "Lato", sans-serif;
}

@media only screen and (max-width: 500px) {
    .navbar-section {
        font-size: 2.1vh;
    }
}
