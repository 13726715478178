@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap");

.about {
    margin-top: -1vh;
    height: auto;
    width: 100%;
    background-image: linear-gradient(#e8ca84 0%, white 50%);
    padding-top: 20vh;
    padding-bottom: 12.5vh;
    display: grid;
    grid-template-columns: 1fr 1fr 2fr 1fr;
    grid-template-rows: 1fr;
    overflow: hidden;
}

.about-bio-container {
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-family: "Lato", sans-serif;
    width: 100%;

    & > * {
        box-sizing: border-box;
        width: 100%;
    }
}

.about-bio-desc {
    font-size: 3vh;
}

.about-bio-title {
    font-weight: bold;
    font-size: 4vh;
    padding-bottom: 2vh;
}

.about-pic {
    border-radius: 50%;
    height: 40vh;
    margin-right: 10vh;
}

.about-pic-container {
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
}

@media only screen and (max-width: 500px) {
    .about {
        display: grid;
        height: auto;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr;
        padding-bottom: 0;
    }

    .about-bio-container {
        justify-content: flex-start;
    }

    .about-bio-container > * {
        box-sizing: border-box;
        width: 100%;
        padding-top: 3vh;
        padding-bottom: 0;
        padding-left: 4vh;
        padding-right: 4vh;
        text-align: center;
    }

    .about-gap {
        display: none;
    }

    .about-pic {
        margin-right: 0;
    }

    .about-pic-container {
        box-sizing: border-box;
        justify-content: center;
        margin-top: 10vh;
    }
}
